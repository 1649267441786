import { graphql } from "gatsby";
import React from "react";
import BottomCTA from "../components/BottomCta";
import AnalyzeSection from "../components/IndexPage/AnalyzeSection";
import AudienceSection from "../components/IndexPage/AudienceSection";
import CustomerStoriesSection from "../components/IndexPage/CustomerStoriesSection";
import GoalSection from "../components/IndexPage/GoalSection";
import HeroSection from "../components/IndexPage/HeroSection";
import PlatformSection from "../components/IndexPage/PlatformSection";
import BMSection from "../components/IndexPage/BMSection";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { useSegment, trackView } from "../utils/analytics";
import { nav } from "../utils/navigation";
import { Name, Type, Location, Category, CTA_ID } from "../utils/analytics/constants";
import { useI18next } from "gatsby-plugin-react-i18next";
import CustomersVote from "../components/IndexPage/CustomersVoteSection";

interface HomePageProps {
  data: any;
}

const IndexPage: React.FC<HomePageProps> = ({ data }) => {
  const { segment, helpers } = useSegment();
  const { language } = useI18next();

  const pageData = data?.homepage?.edges[0]?.node;

  return (
    <Layout
      primaryButtonId={CTA_ID.getStartedNav.id}
      secondaryButtonId={CTA_ID.signinNav.id}
      locale={language}
    >
      <SEO
        title={pageData?.seoSettings?.metadataTitle}
        description={pageData?.seoSettings?.metadataDescription}
        image={pageData?.seoSettings?.openGraphImage?.url}
        canonical={pageData?.seoSettings?.canonicalUrl}
        robots={pageData?.seoSettings?.robots}
      />
      <HeroSection
        title={pageData?.heroMainTitle}
        gradientPosition={pageData?.heroMainTitleGradientPosition}
        description={pageData?.heroDescription?.heroDescription}
        requestDemo={pageData?.heroRequestDemo}
        cta={pageData?.heroCta}
        secondaryCta={pageData?.secondaryHeroCta}
      />
      <PlatformSection
        title={[pageData?.platformSectionTitle]}
        description={pageData?.platformDescription?.platformDescription}
        features={pageData?.platformFeatures}
      />
      <GoalSection
        title={pageData?.goalSectionGradientTitle}
        gradientPosition={pageData?.goalTitleGradientPosition}
        description={pageData?.goalSectionDescription?.goalSectionDescription}
        backgroundImage={pageData?.goalSectionBackgroundImage?.gatsbyImageData}
        features={pageData?.goalFeatures}
        testimonialsDescription={
          pageData?.goalTestimonialDescription?.goalTestimonialDescription
        }
        testimonialsPerson={pageData?.goalTestimonialPerson}
        testimonialsPersonTitle={pageData?.goalTestimonialPersonTitle}
      />
      <AudienceSection
        title={pageData?.audienceSectionGradientTitle}
        gradientPosition={pageData?.audienceGradientTitlePosition}
        description={
          pageData?.audienceSectionDescription?.audienceSectionDescription
        }
        testimonialsDescription={
          pageData?.audienceTestimonialDescription
            ?.audienceTestimonialDescription
        }
        testimonialsPerson={pageData?.audienceTestimonialPerson}
        testimonialsPersonTitle={pageData?.audienceTestimonialPersonTitle}
        testimonialsPersonImage={pageData?.audienceTestimonialPersonImage}
        recommendations={pageData?.audienceRecommendations}
      />
      <AnalyzeSection
        title={pageData?.analyzeSectionGradientTitle}
        gradientPosition={pageData?.analyzeSectionGradientTitlePosition}
        description={
          pageData?.analyzeSectionDescription?.analyzeSectionDescription
        }
        testimonialsDescription={
          pageData?.analyzeTestimonialDescription?.analyzeTestimonialDescription
        }
        testimonialsPerson={pageData?.analyzeTestimonialPerson}
        testimonialsPersonTitle={pageData?.analyzeTestimonialPersonTitle}
        testimonialsPersonImage={
          pageData?.analyzeTestimonialPersonImage?.openGraphImage
        }
        features={pageData?.analyzeFeatures}
      />
      {language !== "ja" && (
        <BMSection
          title={pageData?.bmSectionGradientTitle}
          gradientPosition={pageData?.bmGradientTitlePosition}
          description={pageData?.bmSectionDescription?.bmSectionDescription}
          image={pageData?.bmImage?.openGraphImage}
          features={pageData?.bmFeatures}
          cta={pageData?.bmSectionCta}
        />
      )}
      {language === "en-US" && (
        <CustomerStoriesSection
          title={pageData?.customerSectionTitle}
          description={pageData?.customerSectionDescription}
        />
      )}
      <CustomersVote
        title={pageData?.customersVoteTitle}
        description={pageData?.customersVoteDescription}
        badges={pageData?.customersVoteBadges}
      />
      <BottomCTA
        primaryButtonId={pageData?.bottomCta?.primaryButtonId}
        secondaryButtonId={pageData?.bottomCta?.secondaryButtonId}
        title={pageData?.bottomCta?.title}
        subtitle={pageData?.bottomCta?.subtitle}
        primaryButtonText={pageData?.bottomCta?.primaryButtonText}
        secondaryButtonText={pageData?.bottomCta?.secondaryButtonText}
        primaryButtonClassName={pageData?.bottomCta?.primaryButtonClassName}
        secondaryButtonClassName={pageData?.bottomCta?.secondaryButtonClassName}
        primaryButtonOnClick={() => {
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: pageData?.bottomCta?.primaryButtonText,
              page_url: window.location.href,
              destination: pageData?.bottomCta?.primaryButtonRoute,
              location: Location.BottomCta
            })
            .then(() => nav(pageData?.bottomCta?.primaryButtonRoute));
        }}
        secondaryButtonOnClick={() => {
          trackView(Name.Cta, {
            category: Category.ButtonClick,
            type: Type.Button,
            text: pageData?.bottomCta?.secondaryButtonText,
            page_url: window.location.href,
            destination: pageData?.bottomCta?.secondaryButtonRoute,
            location: Location.BottomCta
          });
          nav(pageData?.bottomCta?.secondaryButtonRoute);
        }}
      />
    </Layout>
  );
};

export default IndexPage;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    homepage: allContentfulHomePageV2(
      filter: { node_locale: { eq: $language } }
    ) {
      edges {
        node {
          seoSettings {
            metadataTitle
            metadataDescription
            openGraphImage {
              gatsbyImageData
              url
            }
            canonicalUrl
            robots
          }
          heroMainTitle
          heroMainTitleGradientPosition
          heroDescription {
            heroDescription
          }
          heroInputPlaceholder
          heroCta {
            text
            route
          }
          secondaryHeroCta {
            route
            text
          }
          heroRequestDemo
          platformSectionTitle
          platformDescription {
            platformDescription
          }
          platformFeatures {
            id
            text
            svg {
              url
            }
          }
          goalSectionGradientTitle
          goalTitleGradientPosition
          goalSectionDescription {
            goalSectionDescription
          }
          goalSectionBackgroundImage {
            gatsbyImageData
            url
          }
          goalFeatures {
            id
            options {
              name
              inputs {
                input
                title
              }
            }
            name
          }
          goalTestimonialDescription {
            goalTestimonialDescription
          }
          goalTestimonialPerson
          goalTestimonialPersonTitle
          audienceSectionGradientTitle
          audienceGradientTitlePosition
          audienceSectionDescription {
            audienceSectionDescription
          }
          audienceRecommendations {
            text
            emphasis
          }
          audienceTestimonialDescription
          audienceTestimonialPerson
          audienceTestimonialPersonTitle
          audienceTestimonialPersonImage {
            gatsbyImageData
            url
          }
          analyzeSectionGradientTitle
          analyzeSectionGradientTitlePosition
          analyzeSectionDescription {
            analyzeSectionDescription
          }
          analyzeTestimonialPersonTitle
          analyzeTestimonialPerson
          analyzeTestimonialDescription {
            analyzeTestimonialDescription
          }
          analyzeTestimonialPersonImage {
            gatsbyImageData
            url
          }
          analyzeFeatures {
            id
            options {
              name
              data {
                title
                amount
                changeAmount
                changeDirection
                position
              }
            }
            name
          }
          bmSectionGradientTitle
          bmGradientTitlePosition
          bmSectionDescription {
            bmSectionDescription
          }
          bmImage {
            gatsbyImageData
            url
          }
          bmFeatures {
            id
            text
            className
          }
          bmSectionCta {
            text
            route
          }
          customerSectionTitle
          customerSectionDescription
          customersVoteTitle
          customersVoteDescription
          customersVoteBadges {
            svg {
              url
            }
            text
          }
          bottomCta {
            title
            subtitle
            primaryButtonId
            secondaryButtonId
            primaryButtonText
            secondaryButtonText
            primaryButtonRoute
            secondaryButtonRoute
            primaryButtonClassName
            secondaryButtonClassName
          }
        }
      }
    }
  }
`;
